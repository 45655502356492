import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css'
import './assets/reset.css'
import './assets/border.css'
import axios from './utils/request.js'
import VueAxios from 'vue-axios'
import Qs from 'qs'// 引入方式
import httpApi from './utils/api.js'
import 'amfe-flexible/index.js'
import './utils/rem.js'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

Vue.prototype.$axios = axios
// axios.defaults.headers.common['token'] = uni.getStorageSync('loginToken')

Vue.prototype.$qs = Qs // 全局加载

Vue.prototype.$api = httpApi

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
