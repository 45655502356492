import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
// 全局路由前置守卫 --路由拦截
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home/home.vue')
  },
  {
    path: '/pcHome',
    name: 'pcHome',
    component: () => import(/* webpackChunkName: "about" */ '../views/pcHome/pcHome.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})
// 全局路由前置守卫 --路由拦截
router.beforeEach((to, from, next) => { // 进入页面前判断登陆
  // if (to.path === '/index.html') return next({ path: '/home' }) // pc的话跳转
  next()
})

export default router
