import axios from './request.js'
// import QS from 'qs'

// headers不能在request.js中统一配置，配置完无效

// 发送短信
const code = (m) => {
  // const token = localStorage.getItem('token')
  return axios({
    method: 'Get',
    url: '/code?mobile=' + m// 'http://localhost:8000/appapi/register/create',
    // data: '',
    // headers: { 'Content-Type': 'application/json' }
  })
}

// 开始测额
const check = (d) => {
  return axios({
    method: 'post',
    url: '/check', // '/appapi/register/sendCode', // 'http://localhost:8000/appapi/register/create',
    data: JSON.stringify(d),
    headers: { 'Content-Type': 'application/json' }
  })
}

// 提交查询
const loans = (d) => {
  return axios({
    method: 'post',
    url: '/loans',
    data: JSON.stringify(d),
    headers: { 'Content-Type': 'application/json' }
  })
}

export default {
  code,
  check,
  loans
}
